import 'slick-carousel';

var scroll_header_appear = false;
$(function () {
    $(window).scroll(function () {
        // スクロールヘッダーフェードイン
        var scroll_header = $('#nav-pc-scrolled');
        if ($(this).scrollTop() > 500) {
            if (scroll_header_appear === false) {
                scroll_header_appear = true;
                scroll_header.fadeIn('slow');
            }
        } else {
            scroll_header_appear = false;
            scroll_header.fadeOut('out');
            $('#scroll-header-overflow-area').fadeOut(10);
            $('.scroll-search-item-area').fadeOut(10);
            $('.scroll-search-item').removeClass('menu-bottom-bar-on');
        }
    });
    
    $(document).ready(function () {
        
        // 部分コンテンツ取得
        $.ajax({
            url: $('#top_contents').val(),
            type: 'GET',
            dataType: 'json',
            beforeSend: function () {
            },   // ajax通信時はロックしないように上書き
        }).done(function (data) {
            let view_render = data.view_render;
            // 特価商品サジェスト
            $('#sale_item_suggest').html(view_render.sale_item_suggest);
            // 新商品サジェスト
            $('#new_item_suggest').html(view_render.new_item_suggest);
            // 最近チェックした商品サジェスト
            $('#check_item_suggest').html(view_render.check_item_suggest);
            
            // リーディング画像用レイアウトクラス削除
            $('.top_page_contents').removeClass();
        })
    });
    
    
    $('.prev-btn').on('click', function () {
        let now_calendar = $(this).parent().attr('class');
        let num = now_calendar.slice(-1);
        let exist_class = $('.calender-area h5').hasClass('calendar-' + (Number(num) - 1));
        if(exist_class) {
            $('.' + now_calendar).css('display','none');
            $('.calendar-' + (Number(num) - 1)).css('display','table');
        }
    });
    
    $('.next-btn').on('click', function () {
        let now_calendar = $(this).parent().attr('class');
        let num = now_calendar.slice(-1);
        let exist_class = $('.calender-area h5').hasClass('calendar-' + (Number(num) + 1));
        if(exist_class) {
            $('.' + now_calendar).css('display','none');
            $('.calendar-' + (Number(num) + 1)).css('display','table');
        }
    });
});
